export const Oscars = [
  {
    id: 2020,
    name: "Oscars",
    year: 2020,
    winners: [
      {
        award: "Best Picture",
        award_id: 1,
        id: 496243,
        title: "Parasite",
        poster_path: "/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg",
        release_date: "2019-05-30",
        genre_ids: [35, 53, 18],
      },
      {
        award: "Best Director",
        award_id: 2,
        id: 496243,
        title: "Parasite",
        poster_path: "/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg",
        release_date: "2019-05-30",
        genre_ids: [35, 53, 18],
      },
      {
        award: "Best Original Screenplay",
        award_id: 3,
        id: 496243,
        title: "Parasite",
        poster_path: "/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg",
        release_date: "2019-05-30",
        genre_ids: [35, 53, 18],
      },
      {
        award: "Best Actor",
        award_id: 4,
        id: 475557,
        title: "Joker",
        poster_path: "/udDclJoHjfjb8Ekgsd4FDteOkCU.jpg",
        release_date: "2019-10-02",
        genre_ids: [80, 53, 18],
      },
      {
        award: "Best Actress",
        award_id: 5,
        id: 491283,
        title: "Judy",
        poster_path: "/iqJhHjD6k6T07waELjMKDpQJUP.jpg",
        release_date: "2019-09-27",
        genre_ids: [18],
      },
      {
        award: "Best Supporting Actor",
        award_id: 6,
        id: 466272,
        title: "Once Upon a Time… in Hollywood",
        poster_path: "/8j58iEBw9pOXFD2L0nt0ZXeHviB.jpg",
        release_date: "2019-07-24",
        genre_ids: [35, 18, 53],
      },
      {
        award: "Best Supporting Actress",
        award_id: 7,
        id: 492188,
        title: "Marriage Story",
        poster_path: "/pZekG6xabTmZxjmYw10wN84Hp8d.jpg",
        release_date: "2019-11-06",
        genre_ids: [18],
      },
      {
        award: "Best Adapted Screenplay",
        award_id: 8,
        id: 515001,
        title: "Jojo Rabbit",
        poster_path: "/7GsM4mtM0worCtIVeiQt28HieeN.jpg",
        release_date: "2019-10-18",
        genre_ids: [35, 10752, 18],
      },
      {
        award: "Best Animated Feature Film",
        award_id: 9,
        id: 301528,
        title: "Toy Story 4",
        poster_path: "/w9kR8qbmQ01HwnvK4alvnQ2ca0L.jpg",
        release_date: "2019-06-19",
        genre_ids: [12, 16, 35, 10751, 14, 18],
      },
      {
        award: "Best International Feature Film",
        award_id: 10,
        id: 496243,
        title: "Parasite",
        poster_path: "/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg",
        release_date: "2019-05-30",
        genre_ids: [35, 53, 18],
      },
      {
        award: "Best Documentary Feature",
        award_id: 11,
        id: 565716,
        title: "American Factory",
        poster_path: "/7jH3dQOJ3CHMrp9tWsI3rRCDiaD.jpg",
        release_date: "2019-08-21",
        genre_ids: [99],
      },
      {
        award: "Best Documentary Short Subject",
        award_id: 12,
        id: 612074,
        title: "Learning to Skateboard in a Warzone (If You're a Girl)",
        poster_path: "/c5F2SBvq7MOYlkoaI5mmlb5V5ka.jpg",
        release_date: "2019-04-28",
        genre_ids: [99],
      },
      {
        award: "Best Live Action Short Film",
        award_id: 13,
        id: 600274,
        title: "The Neighbors' Window",
        poster_path: "/gZJmwQTaUf5iJzcENxtqPa9uxBg.jpg",
        release_date: "2019-08-02",
        genre_ids: [18],
      },
      {
        award: "Best Animated Short Film",
        award_id: 14,
        id: 589739,
        title: "Hair Love",
        poster_path: "/pm9uRa7031Z56unxNE8AqE8f2wg.jpg",
        release_date: "2019-08-14",
        genre_ids: [16, 10751, 18],
      },
      {
        award: "Best Original Score",
        award_id: 15,
        id: 475557,
        title: "Joker",
        poster_path: "/udDclJoHjfjb8Ekgsd4FDteOkCU.jpg",
        release_date: "2019-10-02",
        genre_ids: [80, 53, 18],
      },
      {
        award: "Best Original Song",
        award_id: 16,
        id: 504608,
        title: "Rocketman",
        poster_path: "/f4FF18ia7yTvHf2izNrHqBmgH8U.jpg",
        release_date: "2019-05-22",
        genre_ids: [18, 10402],
      },
      {
        award: "Best Sound Editing",
        award_id: 17,
        id: 359724,
        title: "Ford v Ferrari",
        poster_path: "/6ApDtO7xaWAfPqfi2IARXIzj8QS.jpg",
        release_date: "2019-11-13",
        genre_ids: [18, 36],
      },
      {
        award: "Best Sound Mixing",
        award_id: 18,
        id: 530915,
        title: "1917",
        poster_path: "/iZf0KyrE25z1sage4SYFLCCrMi9.jpg",
        release_date: "2019-12-25",
        genre_ids: [10752, 18, 28],
      },
      {
        award: "Best Production Design",
        award_id: 19,
        id: 466272,
        title: "Once Upon a Time… in Hollywood",
        poster_path: "/8j58iEBw9pOXFD2L0nt0ZXeHviB.jpg",
        release_date: "2019-07-24",
        genre_ids: [35, 18, 53],
      },
      {
        award: "Best Cinematography",
        award_id: 20,
        id: 530915,
        title: "1917",
        poster_path: "/iZf0KyrE25z1sage4SYFLCCrMi9.jpg",
        release_date: "2019-12-25",
        genre_ids: [10752, 18, 28],
      },
      {
        award: "Best Makeup and Hairstyling",
        award_id: 21,
        id: 525661,
        title: "Bombshell",
        poster_path: "/gbPfvwBqbiHpQkYZQvVwB6MVauV.jpg",
        release_date: "2019-12-13",
        genre_ids: [18],
      },
      {
        award: "Best Costume Design",
        award_id: 22,
        id: 331482,
        title: "Little Women",
        poster_path: "/yn5ihODtZ7ofn8pDYfxCmxh8AXI.jpg",
        release_date: "2019-12-25",
        genre_ids: [18, 10749],
      },
      {
        award: "Best Film Editing",
        award_id: 23,
        id: 359724,
        title: "Ford v Ferrari",
        poster_path: "/6ApDtO7xaWAfPqfi2IARXIzj8QS.jpg",
        release_date: "2019-11-13",
        genre_ids: [18, 36],
      },
      {
        award: "Best Visual Effects",
        award_id: 24,
        id: 530915,
        title: "1917",
        poster_path: "/iZf0KyrE25z1sage4SYFLCCrMi9.jpg",
        release_date: "2019-12-25",
        genre_ids: [10752, 18, 28],
      },
    ],
  },
  {
    id: 2019,
    name: "Oscars",
    year: 2019,
    winners: [
      {
        award: "Best Picture",
        award_id: 1,
        id: 490132,
        title: "Green Book",
        poster_path: "/7BsvSuDQuoqhWmU2fL7W2GOcZHU.jpg",
        release_date: "2019-02-25",
        genre_ids: [18, 35],
      },
      {
        award: "Best Director",
        award_id: 2,
        id: 426426,
        title: "Roma",
        poster_path: "/laJbeZT7SQLLKSguSr27v3UO22H.jpg",
        release_date: "2018-08-25",
        genre_ids: [18],
      },
      {
        award: "Best Original Screenplay",
        award_id: 3,
        id: 490132,
        title: "Green Book",
        poster_path: "/7BsvSuDQuoqhWmU2fL7W2GOcZHU.jpg",
        release_date: "2019-02-25",
        genre_ids: [18, 35],
      },
      {
        award: "Best Actor",
        award_id: 4,
        id: 424694,
        title: "Bohemian Rhapsody",
        poster_path: "/lHu1wtNaczFPGFDTrjCSzeLPTKN.jpg",
        release_date: "2018-10-24",
        genre_ids: [18, 10402],
      },
      {
        award: "Best Actress",
        award_id: 5,
        id: 375262,
        title: "The Favourite",
        poster_path: "/cwBq0onfmeilU5xgqNNjJAMPfpw.jpg",
        release_date: "2018-11-23",
        genre_ids: [18, 36, 35],
      },
      {
        award: "Best Supporting Actor",
        award_id: 6,
        id: 490132,
        title: "Green Book",
        poster_path: "/7BsvSuDQuoqhWmU2fL7W2GOcZHU.jpg",
        release_date: "2019-02-25",
        genre_ids: [18, 35],
      },
      {
        award: "Best Supporting Actress",
        award_id: 7,
        id: 465914,
        title: "If Beale Street Could Talk",
        poster_path: "/8tZx0OX7kxv6F2VNWZoPr2bWDgE.jpg",
        release_date: "2018-12-14",
        genre_ids: [18, 10749],
      },
      {
        award: "Best Adapted Screenplay",
        award_id: 8,
        id: 487558,
        title: "BlacKkKlansman",
        poster_path: "/z8tKeTOfZBLsuWsTh2nxWTR5ykN.jpg",
        release_date: "2018-07-30",
        genre_ids: [80, 18, 36, 35],
      },
      {
        award: "Best Animated Feature Film",
        award_id: 9,
        id: 324857,
        title: "Spider-Man: Into the Spider-Verse",
      },
      {
        award: "Best International Feature Film",
        award_id: 10,
        id: 426426,
        title: "Roma",
        poster_path: "/laJbeZT7SQLLKSguSr27v3UO22H.jpg",
        release_date: "2018-08-25",
        genre_ids: [18],
      },
      {
        award: "Best Documentary Feature",
        award_id: 11,
        id: 515042,
        title: "Free Solo",
        poster_path: "/v4QfYZMACODlWul9doN9RxE99ag.jpg",
        release_date: "2018-09-28",
        genre_ids: [99],
      },
      {
        award: "Best Documentary Short Subject",
        award_id: 12,
        id: 524288,
        title: "Period. End of Sentence.",
        poster_path: "/pKYHYlujtTDS0kZM80MUX7l3T58.jpg",
        release_date: "2018-04-0",
        genre_ids: [99],
      },
      {
        award: "Best Live Action Short Film",
        award_id: 13,
        id: 569485,
        title: "Skin",
        poster_path: "/3fuif2SyRehNE68JYlC0uBWROVO.jpg",
        release_date: "2018-08-18",
        genre_ids: [18],
      },
      {
        award: "Best Animated Short Film",
        award_id: 14,
        id: 514754,
        title: "Bao",
        poster_path: "/tKz7XRXvdy1i7pW4eotaWZSrAx2.jpg",
        release_date: "2018-06-15",
        genre_ids: [16, 10751, 14],
      },
      {
        award: "Best Original Score",
        award_id: 15,
        id: 284054,
        title: "Black Panther",
        poster_path: "/uxzzxijgPIY7slzFvMotPv8wjKA.jpg",
        release_date: "2018-02-13",
        genre_ids: [28, 12, 14, 878],
      },
      {
        award: "Best Original Song",
        award_id: 16,
        id: 332562,
        title: "A Star Is Born",
        poster_path: "/wrFpXMNBRj2PBiN4Z5kix51XaIZ.jpg",
        release_date: "2018-10-03",
        genre_ids: [18, 10749, 10402],
      },
      {
        award: "Best Sound Editing",
        award_id: 17,
        id: 424694,
        title: "Bohemian Rhapsody",
        poster_path: "/lHu1wtNaczFPGFDTrjCSzeLPTKN.jpg",
        release_date: "2018-10-24",
        genre_ids: [18, 10402],
      },
      {
        award: "Best Sound Mixing",
        award_id: 18,
        id: 424694,
        title: "Bohemian Rhapsody",
        poster_path: "/lHu1wtNaczFPGFDTrjCSzeLPTKN.jpg",
        release_date: "2018-10-24",
        genre_ids: [18, 10402],
      },
      {
        award: "Best Production Design",
        award_id: 19,
        id: 284054,
        title: "Black Panther",
        poster_path: "/uxzzxijgPIY7slzFvMotPv8wjKA.jpg",
        release_date: "2018-02-13",
        genre_ids: [28, 12, 14, 878],
      },
      {
        award: "Best Cinematography",
        award_id: 20,
        id: 426426,
        title: "Roma",
        poster_path: "/laJbeZT7SQLLKSguSr27v3UO22H.jpg",
        release_date: "2018-08-25",
        genre_ids: [18],
      },
      {
        award: "Best Makeup and Hairstyling",
        award_id: 21,
        id: 429197,
        title: "Vice",
        poster_path: "/1gCab6rNv1r6V64cwsU4oEr649Y.jpg",
        release_date: "2018-12-25",
        genre_ids: [18, 36, 35],
      },
      {
        award: "Best Costume Design",
        award_id: 22,
        id: 284054,
        title: "Black Panther",
        poster_path: "/uxzzxijgPIY7slzFvMotPv8wjKA.jpg",
        release_date: "2018-02-13",
        genre_ids: [28, 12, 14, 878],
      },
      {
        award: "Best Film Editing",
        award_id: 23,
        id: 424694,
        title: "Bohemian Rhapsody",
        poster_path: "/lHu1wtNaczFPGFDTrjCSzeLPTKN.jpg",
        release_date: "2018-10-24",
        genre_ids: [18, 10402],
      },
      {
        award: "Best Visual Effects",
        award_id: 24,
        id: 369972,
        title: "First Man",
        poster_path: "/i91mfvFcPPlaegcbOyjGgiWfZzh.jpg",
        release_date: "2018-10-11",
        genre_ids: [36, 18],
      },
    ],
  },
];
